import config from "constants/config";
import httpClientV1 from 'http-common-v1';
import * as _ from "lodash";
import { useState } from 'react';
import {
    Button, Col, Form, Modal, Row, Table
} from "react-bootstrap";
import moment from '../../../node_modules/moment/moment';


function V1AssignedTranslatorOrQA(props) {
    const { show, onHide, type, itemData } = props
    const [value, setValue] = useState("")
    const [selectItemValue, setSelectItemValue] = useState(null)
    const [search, setSearch] = useState('');

    const [translators, setTranslators] = useState([]);
    const [allTranslators, setAllTranslators] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedTime, setSelectedTime] = useState();


    const [tatDate, setTatDate] = useState();
    const [tatTime, setTatTime] = useState();




    const closeModal = (type) => {
        if (selectedDate != undefined && type === 1) {
            const dateValue = moment(selectedDate).format("YYYY-MM-DD")
            const dateWithTime = dateValue + ":" + selectedTime;
            const newDateWithTime = (new Date(dateWithTime)).getTime();
            setTatDate(newDateWithTime)
            onHide(type, selectItemValue, newDateWithTime);
        } else {
            onHide(type, '', '');

        }

    }

    const getAllTranslator = async () => {
        const token = localStorage.getItem('token')
        try {
            const getAllTranslator = await httpClientV1.get(config.V1_AUTH_API_URL + "/translators")
            if (getAllTranslator.data.data.length > 0) {
                const getDataValue = _.map(getAllTranslator.data.data, (v) => {
                    return {
                        name: v.userDetails?.firstName + ' ' + v.userDetails?.lastName,
                        email: v.email,
                        dId: v.id,
                        uuid: v.uuid,
                        contactNum: v.mobile
                    }
                })
                setTranslators(getDataValue)
                setAllTranslators(getDataValue)
            }

        } catch (error) {
            console.log(error)
        }

    }
    const findObjectsByKeyLike = (arr, key, substring) => {
        const lowerCaseSubstring = substring.toLowerCase();
        return arr.filter(item => item[key].toLowerCase().includes(lowerCaseSubstring));
    }

    return (

        <Modal {...props} size="lg" backdrop="static" onShow={getAllTranslator}>
            <Modal.Header>
                {type === 1 && <Modal.Title>Assigned Translator</Modal.Title>}
                {type === 2 && <Modal.Title>Assigned QA</Modal.Title>}
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Form.Control type="text" placeholder="Search by name" value={search} onChange={(v) => {
                        setSearch(v.target.value)
                        setTimeout(() => {
                            const valueData = findObjectsByKeyLike(allTranslators, 'name', v.target.value);
                            setTranslators(valueData)

                        }, 1000);
                    }} />
                </Row>


                <Row style={{ marginTop: "10px" }}>
                    <Col>

                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Select TAT Date
                            </label>
                            <Form.Control type="date" min={new Date().toISOString().slice(0, 10)} value={selectedDate} onChange={(v) => {
                                const dateValue = new Date(v.target.value);
                                setSelectedDate(v.target.value)

                            }} />

                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <label htmlFor="exampleInputName">
                                Select TAT Time
                            </label>
                            <Form.Control type="time" value={selectedTime} onChange={(v) => {
                                setSelectedTime(v.target.value)
                            }} />

                        </Form.Group>
                    </Col>
                </Row>


                <Row style={{ height: "200px", overflow: "auto", marginTop: "10px" }}>
                    <Table className="table-hover table-striped" >
                        <thead>
                            <tr>
                                <th></th>
                                <th className="border-0">Name</th>
                                <th className="border-0">Email</th>
                                <th className="border-0">contactNum</th>

                            </tr>
                        </thead>
                        <tbody >
                            {translators.length > 0 && translators.map((v, index) => {
                                return <tr key={index}>
                                    <td>
                                        <Form.Check
                                            onClick={
                                                (vs) => {
                                                    setSelectItemValue(v)
                                                }
                                            }
                                            name='group1'
                                            type="radio"
                                            id={`default-checkbox`}
                                        />
                                    </td>
                                    <td>{v.name}</td>
                                    <td>{v.email}</td>
                                    <td>{v.contactNum}</td>

                                </tr>;
                            })}

                        </tbody>

                    </Table>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal(0)}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={(selectItemValue === null || selectedTime === undefined || selectedDate === undefined) ? true : false} onClick={() => closeModal(1)} >
                    Assigned
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default V1AssignedTranslatorOrQA;